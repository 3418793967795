<footer *ngIf="rootPage$ | async as rootPage">
  <img class="hartje" src="../../assets/images/hartje-01.svg" alt="hartje" />
  <section *ngIf="rootPage.tickets_text || rootPage.tickets_url" class="tickets">
    <h1>Tickets</h1>
    <lacerta-rich-text-block [content]="rootPage.tickets_text"></lacerta-rich-text-block>
    <div class="right">
      <a [href]="rootPage.tickets_url" target="_blank">Naar de ticketshop</a>
    </div>
  </section>
  <section *ngIf="rootPage.footer_text" class="text">
    <lacerta-rich-text-block [content]="rootPage.footer_text"></lacerta-rich-text-block>
  </section>
  <section *ngIf="rootPage.partners" class="partners">
    <bas-header theme="green"><h2>Partners</h2></bas-header>
    <ol>
      <li *ngFor="let partner of rootPage.partners | streamFieldsToValues">
        <a [href]="partner.url" target="_blank" [title]="partner.name">
          <img *ngIf="partner.file.file_extension === 'svg'" [src]="partner.file.url" [alt]="partner.name" loading="lazy" />
          <img *ngIf="partner.file.renditions" [src]="partner.file.renditions['small'].src" [alt]="partner.name" loading="lazy" />
        </a>
      </li>
    </ol>
  </section>
  <section *ngIf="rootPage.contact" class="contact">
    <bas-header theme="green"><h2>Contact</h2></bas-header>
    <div fxLayout="row" fxLayout.lt-md="column" fxFlexFill fxLayoutGap="var(--space-4)">
      <div fxFlex="1 1 0%">
        <lacerta-rich-text-block [content]="rootPage.contact"></lacerta-rich-text-block>
      </div>
      <div fxFlex="1 1 0%" class="right">
        <picture>
          <source srcset="../../assets/images/footer.svg" />
          <img src="../../assets/images/footer.png" alt="Festival op het ritme van je hart." loading="lazy" />
        </picture>
      </div>
    </div>
  </section>
</footer>
