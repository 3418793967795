import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BobbieEnSaulAppComponent } from './app.component';
import { BobbieEnSaulCoreModule } from './core/core.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { registerLocaleData } from '@angular/common';

import localeNl from '@angular/common/locales/nl';
import { FooterModule } from './footer/footer.module';
import { LacertaSocialLinkModule } from '@lacerta/ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GetTicketsModules } from './feature/gettickets/gettickets.module';
import { FormBuilder } from '@angular/forms';

registerLocaleData(localeNl);

@NgModule({
  imports: [
    BrowserModule,
    BobbieEnSaulCoreModule,
    FlexLayoutModule,
    FooterModule,
    LacertaSocialLinkModule,
    FontAwesomeModule,
    GetTicketsModules,
  ],
  declarations: [BobbieEnSaulAppComponent],
  bootstrap: [BobbieEnSaulAppComponent],
  providers: [FormBuilder],
})
export class AppModule {}
