import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { cast } from '@lacerta/util';
import { BobbieEnSaulRootPage } from '../wagtail/page.model';

export interface VsaFooterSlugs {
  disclaimer: string;
  privacy: string;
  cookies: string;
}

@Component({
  selector: 'bas-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  constructor(public lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}

  rootPage$: Observable<BobbieEnSaulRootPage> = this.lacertaCmsNgrxFacade.rootPage$.pipe(take(1), cast<BobbieEnSaulRootPage>());
}
