import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bas-header',
  template: ` <div class="hgroup" [ngClass]="theme">
    <ng-content select="h1, h2, h3, h4, h5, h6"></ng-content>
  </div>`,
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() theme: string | string[] = [];
}
