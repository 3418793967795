import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { GetticketsComponent } from './gettickets.component';

@NgModule({
  declarations: [GetticketsComponent],
  imports: [SharedModule],
  exports: [GetticketsComponent],
})
export class GetTicketsModules {}
