import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

const moveInTimeout = 1000;
const moveOutTimeout = 13000;
const boxMoveInTimeout = 15000;

@Component({
  selector: 'bas-get-tickets',
  template: `
    <div class="get_your_tickets" [ngClass]="[animation, shape, theme]">
      <a *ngIf="!href" [routerLink]="routerLink">
        <div class="text">
          <span #content1><ng-content></ng-content></span><span *ngIf="!content1.innerHTML.trim()">Tickets</span>
        </div>
      </a>
      <a *ngIf="href" [href]="href" target="_blank">
        <div class="text">
          <span #content2><ng-content></ng-content></span><span *ngIf="!content2.innerHTML.trim()">Tickets</span>
        </div>
      </a>
    </div>
  `,
  styleUrls: ['./gettickets.component.scss'],
})
export class GetticketsComponent implements OnInit, OnDestroy {
  animation = '';
  shape = '';
  timeoutIDs: number[] = [];
  @Input() href?: SafeResourceUrl;
  @Input() routerLink = '/about';
  @Input() theme = '';

  ngOnInit(): void {
    this.shape = 'circle';

    this.timeoutIDs.push(
      window.setTimeout(() => {
        this.animation = 'move-in';
      }, moveInTimeout),
      window.setTimeout(() => {
        this.animation = 'move-out';
      }, moveOutTimeout),
      window.setTimeout(() => {
        this.shape = 'box';
        this.animation = 'move-in';
      }, boxMoveInTimeout)
    );
  }

  ngOnDestroy(): void {
    this.hide();
  }

  hide(): void {
    this.timeoutIDs.forEach(clearTimeout);
    this.timeoutIDs.length = 0;
    this.animation = 'move-out';
  }
}
