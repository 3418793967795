import { NgModule } from '@angular/core';
import { LacertaCoreModule } from '@lacerta/core';
import { LacertaWagtailComponentTypeMapping } from '@lacerta/cms';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { environment } from '../../environments/environment';
import { BobbieEnSaulWagtailPageType } from '../wagtail/page.model';
import { BobbieEnSaulWagtailBlockType } from '../wagtail/block.model';
import { BobbieEnSaulCustomPath } from './custom-path.model';
import { BobbieEnSaulLandingPageComponent } from '../wagtail/page/landing-page.component';

const wagtailComponentTypeMapping: LacertaWagtailComponentTypeMapping = [
  [
    BobbieEnSaulWagtailPageType.home,
    () => import('../wagtail/page/home-page.component').then((component) => component.BobbieEnSaulHomePageComponent),
  ],
  [
    BobbieEnSaulWagtailPageType.content,
    () => import('../wagtail/page/content-page.component').then((component) => component.BobbieEnSaulContentPageComponent),
  ],
  [
    BobbieEnSaulWagtailBlockType.text,
    () => import('../wagtail/block/text-block.component').then((component) => component.BobbieEnSaulTextBlockComponent),
  ],
  [
    BobbieEnSaulWagtailBlockType.greenText,
    () => import('../wagtail/block/green-text-block.component').then((component) => component.BobbieEnSaulGreenTextBlockComponent),
  ],
  [
    BobbieEnSaulWagtailBlockType.lineUp,
    () => import('../wagtail/block/line-up-block.component').then((component) => component.BobbieEnSaulLineUpBlockComponent),
  ],
  [
    BobbieEnSaulWagtailBlockType.photo,
    () => import('../wagtail/block/photo-block.component').then((component) => component.BobbieEnSaulPhotoBlockComponent),
  ],
  [
    BobbieEnSaulWagtailBlockType.video,
    () => import('../wagtail/block/video-block.component').then((component) => component.BobbieEnSaulVideoBlockComponent),
  ],
  [
    BobbieEnSaulWagtailBlockType.header,
    () => import('../wagtail/block/header-block.component').then((component) => component.BobbieEnSaulHeaderBlockComponent),
  ],
  [
    BobbieEnSaulWagtailBlockType.shop,
    () => import('../wagtail/block/shop-block.component').then((component) => component.BobbieEnSaulShopBlockComponent),
  ],
];

@NgModule({
  imports: [
    LacertaCoreModule.forRoot({
      wagtailComponentTypeMapping,
      metaConfig: {},
      rootPageType: BobbieEnSaulWagtailPageType.root,
      customRoutes: [{ path: BobbieEnSaulCustomPath.landing_page, component: BobbieEnSaulLandingPageComponent }],
      menuParentPageType: BobbieEnSaulWagtailPageType.root,
      environment,
    }),
    InlineSVGModule.forRoot(),
  ],
  providers: [],
  exports: [RouterModule],
})
export class BobbieEnSaulCoreModule {}
