import { Component } from '@angular/core';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';
import { cast, filterNullOrUndefined } from '@lacerta/util';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Observable } from 'rxjs';
import { BobbieEnSaulRootPage } from './wagtail/page.model';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'bas-root',
  template: `
    <section class="social_links">
      <a href="https://www.facebook.com/bobbiesaulfestival" target="_blank">
        <fa-icon [icon]="icons.facebook"></fa-icon>
      </a>
      <a href="https://www.instagram.com/bobbiesaulfestival/" target="_blank">
        <fa-icon [icon]="icons.instagram"></fa-icon>
      </a>
    </section>
    <router-outlet></router-outlet>
    <bas-get-tickets *ngIf="ticketsUrl$ | async as ticketsUrl" [href]="ticketsUrl"></bas-get-tickets>
    <bas-footer></bas-footer>
  `,
  styleUrls: ['./app.component.scss'],
})
export class BobbieEnSaulAppComponent {
  icons = {
    facebook: faFacebookF,
    instagram: faInstagram,
  };

  constructor(public lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}

  ticketsUrl$: Observable<string> = this.lacertaCmsNgrxFacade.rootPage$.pipe(
    take(1),
    cast<BobbieEnSaulRootPage>(),
    map((rootPage) => rootPage.tickets_url)
  );

  selectDetailPageFilterNull = (id: number) => this.lacertaCmsNgrxFacade.selectDetailPageById(id).pipe(filterNullOrUndefined());
}
