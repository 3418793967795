import { Component, Input, NgModule, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LacertaDynamicModule } from '@lacerta/cms';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Join } from '../page.model';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'bas-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class BobbieEnSaulLandingPageComponent implements OnInit, OnDestroy {
  @Input() hideFooter = true;

  checkoutForm = this.formBuilder.group({
    code: '',
  });
  errorMessage = '';

  constructor(private renderer: Renderer2, private formBuilder: FormBuilder, private http: HttpClient) {}

  ngOnInit(): void {
    if (this.hideFooter) {
      this.renderer.addClass(document.body, 'hide-footer');
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'hide-footer');
  }

  onSubmit(): void {
    console.warn('form submit', this.checkoutForm.value);
    this.http
      .get<Join>(`/join/${this.checkoutForm.value.code}`)
      .pipe(
        catchError((httpError: unknown) => {
          console.warn(httpError);
          this.checkoutForm.reset();
          this.errorMessage = 'Oeps, dat wachtwoord klopt niet. Probeer het nog eens, Bobbie & Saul wachten wel.';
          return throwError('Something bad happened; please try again later.');
        })
      )
      .subscribe((data) => {
        window.location.href = data['url'];
      });
  }
}

@NgModule({
  imports: [CommonModule, LacertaDynamicModule, InlineSVGModule, BrowserModule, FormsModule, ReactiveFormsModule, RouterModule],
  declarations: [BobbieEnSaulLandingPageComponent],
  providers: [FormBuilder],
  exports: [BobbieEnSaulLandingPageComponent],
})
export class BobbieEnSaulLandingPageModule {}
