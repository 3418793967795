/* eslint-disable @typescript-eslint/naming-convention */
import { ListBlock, WagtailStructBlock } from '@lacerta/wagtail';
import { LacertaImageChooserBlock } from '@lacerta/cms';

export enum BobbieEnSaulWagtailBlockType {
  // ImageChooserBlock
  photo = 'photo',
  // TextBlock
  text = 'text',
  // TextBlock
  header = 'header',
  // VideoBlock
  video = 'video',
  greenText = 'green_text',
  lineUp = 'line_up',
  // PageChooserBlock
  shop = 'shop',
}

export interface LineUpBlock extends WagtailStructBlock {
  bobbie: ListBlock<LineUpItem>;
  saul: ListBlock<LineUpItem>;
}

export interface LineUpItem extends WagtailStructBlock {
  title: string;
  start: string;
}

interface ShopItem {
  item: string;
  shop: string;
  image: LacertaImageChooserBlock;
  link: string;
}

export interface ShopBlock extends WagtailStructBlock {
  intro: string;
  items: ListBlock<ShopItem>;
}

export interface HeaderBlock extends WagtailStructBlock {
  line_1: string;
  line_2: string;
}
