import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { LacertaEmailModule, LacertaImageModule, LacertaPhoneModule, LacertaSocialLinkModule } from '@lacerta/ui';
import { SharedModule } from '../shared/shared.module';
import { BobbieEnSaulHeaderBlockModule } from '../wagtail/block/header-block.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    SharedModule,
    LacertaPhoneModule,
    LacertaEmailModule,
    LacertaSocialLinkModule,
    LacertaImageModule,
    BobbieEnSaulHeaderBlockModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
