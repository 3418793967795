import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { LacertaCmsBlocksModule, LacertaDynamicTypeComponent } from '@lacerta/cms';
import { CommonModule } from '@angular/common';
import { HeaderBlock } from '../block.model';
import { SharedModule } from '../../shared/shared.module';

@Component({
  template: `<bas-header *ngIf="context">
    <h2>{{ context?.line_1 }}</h2>
    <h2 *ngIf="context?.line_2">{{ context?.line_2 }}</h2>
  </bas-header>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      bas-header {
        margin: 0 var(--gutter-width);
      }
    `,
  ],
})
export class BobbieEnSaulHeaderBlockComponent extends LacertaDynamicTypeComponent<HeaderBlock> {}

@NgModule({
  imports: [CommonModule, LacertaCmsBlocksModule, SharedModule],
  declarations: [BobbieEnSaulHeaderBlockComponent],
  exports: [BobbieEnSaulHeaderBlockComponent],
})
export class BobbieEnSaulHeaderBlockModule {}
