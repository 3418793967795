<main>
  <section class="form">
    <div class="container">
      <div class="svg1" inlineSVG="../../assets/images/landing_1.svg"></div>
      <h1>
        Bobbie &amp; Saul are<br />
        ready to <span>b</span><span>u</span><span>b</span><span>b</span><span>l</span><span>e</span>!
      </h1>
      <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
        <div>
          <label for="code"></label
          ><input id="code" type="text" formControlName="code" placeholder="wachtwoord" autocomplete="off" autofocus />
          <div class="error" *ngIf="errorMessage">
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <button class="button" type="submit"><span inlineSVG="../../assets/images/landing_pijltje.svg"></span></button>
      </form>
      <div class="svg2" inlineSVG="../../assets/images/landing_2.svg"></div>
    </div>
  </section>
  <section class="footer">
    <p>vergeet niet om hen straks even te gaan groeten in de chat</p>
    <a routerLink="/home"><div class="logo" inlineSVG="../../assets/images/logo.svg"></div></a>
  </section>
</main>
