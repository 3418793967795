/* eslint-disable @typescript-eslint/naming-convention */
import { LacertaDocumentChooserBlock, LacertaDynamicWagtailPage, LacertaImageChooserBlock, LacertaWagtailPage } from '@lacerta/cms';
import { WagtailImageRenditionField, WagtailStreamField } from '@lacerta/wagtail';

export enum BobbieEnSaulWagtailPageType {
  home = 'bobbie_en_saul.BobbieSaulHomePage',
  content = 'bobbie_en_saul.BobbieSaulContentPage',
  root = 'bobbie_en_saul.BobbieSaulRootPage',
}

export interface PartnerBlock {
  url: string;
  name: string;
  file: LacertaDocumentChooserBlock | LacertaImageChooserBlock;
}

export interface BobbieEnSaulRootPage extends LacertaWagtailPage {
  tickets_text: string;
  tickets_url: string;
  footer_text: string;
  contact: string;
  partners: WagtailStreamField<PartnerBlock>[];
}

export interface BobbieEnSaulHomePage extends LacertaDynamicWagtailPage {
  hero_landscape: WagtailImageRenditionField;
  hero_portrait: WagtailImageRenditionField;
}

export interface Join {
  url: string;
}
